<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <div class="basic-table">
              <div
                class="row basic-table__header no-gutters align-items-center"
              >
                <div class="col-4">
                  <h3 class="basic-table__title text-seizeblue">
                    렌탈요금 납부 집계
                  </h3>
                </div>
              </div>
              <div class="basic-table__table">
                <div class="row">
                  <div class="col-1"></div>
                  <div class="col-4">
                    <v-data-table
                      :headers="sumTableHeader"
                      :items="sumTableData"
                      class="seize-table-box"
                      dense
                      hide-default-footer
                      item-key="key"
                      loading-text="데이터 조회중입니다..."
                    >
                      <template v-slot:item.key="{ item }">
                        <span style="color: #000000; font-weight: bold;">{{
                          getMonthString(item.key)
                        }}</span>
                      </template>
                      <template v-slot:item.value.count="{ item }">
                        <span style="color: #000000;">{{
                          getCountString(item.value.count)
                        }}</span>
                      </template>
                      <template v-slot:item.value.sum="{ item }">
                        <span style="color: #000000;">{{
                          getMoneyString(item.value.sum)
                        }}</span>
                      </template>
                    </v-data-table>
                  </div>
                  <div class="col-1"></div>
                  <div class="col-6">
                    <div class="p-2 col text-right">
                      <b-button
                        :pressed.sync="valueMode"
                        class="seize-base-margin seizeblue-button"
                        variant="outline-seizeblue"
                        v-on:click="initValueMode"
                        >금액
                      </b-button>
                      <b-button
                        :pressed.sync="countMode"
                        class="seize-base-margin seizeblue-button"
                        variant="outline-seizeblue"
                        v-on:click="initCountMode"
                        >건수
                      </b-button>
                    </div>
                    <canvas ref="chart" height="100"></canvas>
                  </div>
                  <div class="col-1"></div>
                </div>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <output-table
              ref="outputTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '렌탈요금 납부 상세',
                titleTextClass: 'text-seizeblue',
                detailData: detailOutputDataArray,
                filterDisabled: {
                  term: false,
                  inout: true,
                  targetInst: true,
                  name: true,
                  level2: true,
                  level3: true,
                  level4: true,
                  level5: true,
                  tranType: true,
                },
                inoutInitialFilter: ['출금'],
                itemsPerPage: 10,
              }"
            >
            </output-table>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <input-table
              ref="inputTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '렌탈요금 입금 상세 - 예외',
                titleTextClass: 'text-seizeblue',
                detailData: detailInputDataArray,
                filterDisabled: {
                  term: false,
                  inout: true,
                  targetInst: true,
                  name: true,
                  level2: true,
                  level3: true,
                  level4: true,
                  level5: true,
                  tranType: true,
                },
                inoutInitialFilter: ['입금'],
                itemsPerPage: 10,
              }"
            >
            </input-table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import TransactionDataTable from "@/views/partials/widgets/tables/TransactionDataTable.vue";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import Chart from "chart.js";

Vue.use(SeizePlugin);
export default {
  name: "Rental",
  components: {
    KTPortlet,
    "input-table": TransactionDataTable,
    "output-table": TransactionDataTable,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      tabIndex: 0,
      tabIndex2: 0,
      categoryMapData: {},
      detailInputDataArray: this.getInputData(),
      detailOutputDataArray: this.getOutputData(),
      sumTableHeader: [
        {
          text: "월",
          sortable: false,
          align: "center",
          value: "key",
          class: "seize-table-header seize-table-box",
        },
        {
          text: "건수",
          sortable: false,
          align: "center",
          value: "value.count",
          class: "seize-table-header seize-table-box",
        },
        {
          text: "합계",
          sortable: false,
          align: "right",
          value: "value.sum",
          class: "seize-table-header seize-table-box",
        },
      ],
      sumTableData: [],
      countMode: false,
      valueMode: true,
      myLineChart: null,
      chartData: [],
      chartOptions: [],
      rawData: [],
      valueMax: 0,
      countMax: 0,
    };
  },
  mounted() {
    document.title = "렌탈요금";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.sumTableData = this.generateTableData();
    this.initChart();
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.detailInputDataArray = this.getInputData();
      this.detailOutputDataArray = this.getOutputData();
      this.sumTableData = this.generateTableData();
      if (this.countMode) {
        this.initCountMode();
      } else {
        this.initValueMode();
      }
      this.myLineChart.update();
      this.$refs.inputTable.setData(this.baseDate, this.detailInputDataArray);
      this.$refs.outputTable.setData(this.baseDate, this.detailOutputDataArray);
    },
    getOutputData() {
      const categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      return this.getOutputCategoryData(categoryMap).sort(function (a, b) {
        return a.tran_date > b.tran_date
          ? -1
          : a.tran_date < b.tran_date
          ? 1
          : 0;
      });
    },
    getOutputCategoryData(categoryMap) {
      let result = [];
      const codeList = ["WDLFRNT00000000"];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
    getInputData() {
      const categoryMap = d3
        .nest()
        .key(function (data) {
          return data.category_code1;
        })
        .map(this.$store.getters.requestItem.items);
      return this.getInputCategoryData(categoryMap).sort(function (a, b) {
        return a.tran_date > b.tran_date
          ? -1
          : a.tran_date < b.tran_date
          ? 1
          : 0;
      });
    },
    getInputCategoryData(categoryMap) {
      let result = [];
      const codeList = [];
      codeList.forEach((element) => {
        if (categoryMap.get(element))
          result = result.concat(categoryMap.get(element));
      });
      return result;
    },
    generateTableData() {
      const baseDate = this.baseDate;
      const before6M = Vue.getBefore6MonthString(baseDate);
      const filteredList = this.detailOutputDataArray.filter(function (value) {
        return (
          Vue.dateToDateString(value.tran_date) >= before6M &&
          Vue.dateToDateString(value.tran_date) <= baseDate
        );
      });
      const monthBaseData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToYearMonthString(data.tran_date);
        })
        .rollup(function (values) {
          return {
            count: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return 1;
              } else {
                return 0;
              }
            }),
            sum: d3.sum(values, function (data) {
              if (data.inout_code === -1) {
                return data.tran_amt;
              } else {
                return 0;
              }
            }),
          };
        })
        .entries(filteredList);
      this.makeEmptyData(monthBaseData, before6M, baseDate);
      monthBaseData.sort(function (a, b) {
        return a.key > b.key ? -1 : a.key < b.key ? 1 : 0;
      });
      this.rawData = [];
      this.rawData = this.rawData.concat(monthBaseData);
      this.rawData.sort(function (a, b) {
        return a.key < b.key ? -1 : a.key > b.key ? 1 : 0;
      });
      return monthBaseData;
    },
    makeEmptyData(monthBaseData, startDate, endDate) {
      const keyExists = function (key) {
        return monthBaseData.some(function (element) {
          return element.key === key;
        });
      };
      while (startDate < endDate) {
        if (!keyExists(Vue.dateToYearMonthString(startDate))) {
          monthBaseData.push({
            key: Vue.dateToYearMonthString(startDate),
            value: { count: 0, sum: 0 },
          });
        }
        startDate = Vue.getAfterMonthString(startDate, 1);
      }
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    initChart() {
      this.countMode = false;
      this.valueMode = true;
      this.chartData = {
        labels: this.makeChartLabel(this.rawData),
        datasets: this.makeChartValueData(this.rawData),
      };
      this.chartOptions = {
        type: "line",
        data: this.chartData,
        options: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  suggestedMin: 0,
                  suggestedMax: this.valueMax + 10000,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItems, data) {
                const value = data.datasets[0].data[tooltipItems.index];
                return "금액 : " + numeral(value).format("0,0") + "원";
              },
            },
          },
        },
      };
      this.myLineChart = this.drawChart(this.chartOptions);
    },
    initCountMode() {
      this.countMode = true;
      this.valueMode = false;
      this.chartData.datasets = this.makeChartCountData(this.rawData);
      this.chartOptions.options.scales.yAxes = [
        {
          display: false,
          ticks: {
            suggestedMin: 0,
            suggestedMax: this.countMax + 5,
          },
        },
      ];
      this.chartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const value = data.datasets[0].data[tooltipItems.index];
          return "건수 : " + numeral(value).format("0,0") + "건";
        },
      };
      this.myLineChart.update();
    },
    initValueMode() {
      this.countMode = false;
      this.valueMode = true;
      this.chartData.datasets = this.makeChartValueData(this.rawData);
      this.chartOptions.options.scales.yAxes = [
        {
          display: false,
          ticks: {
            suggestedMin: 0,
            suggestedMax: this.valueMax + 10000,
          },
        },
      ];
      this.chartOptions.options.tooltips.callbacks = {
        label: function (tooltipItems, data) {
          const value = data.datasets[0].data[tooltipItems.index];
          return "금액 : " + numeral(value).format("0,0") + "원";
        },
      };
      this.myLineChart.update();
    },
    makeChartLabel(rawData) {
      const result = [];
      rawData.forEach((element) => {
        result.push(Vue.dateToMonthString(element.key + "-01"));
      });
      return result;
    },
    makeChartCountData(rawData) {
      const result = [];
      const data = {
        label: "렌탈요금",
        backgroundColor: "rgba(225,240,255, 1)",
        borderColor: "rgba(61,157, 255, 1)",
        fill: "start",
      };
      const value = [];
      rawData.forEach((element) => {
        value.push(element.value.count);
        if (this.countMax < element.value.count)
          this.countMax = element.value.count;
      });
      data.data = value;
      result.push(data);
      return result;
    },
    makeChartValueData(rawData) {
      const result = [];
      const data = {
        label: "렌탈요금",
        backgroundColor: "rgba(225,240,255, 1)",
        borderColor: "rgba(61,157, 255, 1)",
        fill: "start",
      };
      const value = [];
      rawData.forEach((element) => {
        value.push(element.value.sum);
        if (this.valueMax < element.value.sum)
          this.valueMax = element.value.sum;
      });
      data.data = value;
      result.push(data);
      return result;
    },
    drawChart(chartOptions) {
      const ctx = this.$refs["chart"].getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 240);
      gradient.addColorStop(
        0,
        Chart.helpers.color("#e14c86").alpha(1).rgbString()
      );
      gradient.addColorStop(
        1,
        Chart.helpers.color("#e14c86").alpha(0.3).rgbString()
      );
      return new Chart(ctx, chartOptions);
    },
    updateChart(chartOptions) {
      this.myLineChart.chartOptions = chartOptions;
      this.myLineChart.update();
    },
    getMonthString(date) {
      return Vue.dateToMonthString(date + "-01");
    },
    getCountString(value) {
      return numeral(value).format("0,0") + "건";
    },
    getMoneyString(value) {
      return numeral(value).format("0,0") + "원";
    },
  },
};
</script>
